import axios, { AxiosResponse } from "axios";
import { Announcement, Course, CourseWork, CourseWorkMaterial, DriveFile, Student, StudentSubmission, StudentSubmissionState, Topic } from "src/types/googleClassroom";
import { toTimestamp, scheduledDateToDueDate, yyyymmddOnLocal } from "src/lib/time-converter";
import { HAIFUBUTSU_TOPIC_NAME, SHUKUDAI_TOPIC_NAME } from "src/constants";
import { DifferenceToRequiredTopics } from "./topic-model";
import { CourseWorkShareMode } from "src/components/print/type-tab/share-mode-select";
import { EventUpdatableProps, ExtendedDriveFile } from "./event";
import dayjs from 'dayjs';

interface CreateCourseWorkMaterialProps {
  scheduledDate:Date | null
  driveFiles:ExtendedDriveFile[]
  courseId: string
  topicId?:string
  title: string
  linkUrls?: string[]
  description?: string
}

interface CreateCourseWorkProps extends CreateCourseWorkMaterialProps {
  readCheck: boolean
  dueDate?: Date
}

export const filterCourseWorkMaterialList = (courseId:string, courseWorks:CourseWork[] | CourseWorkMaterial[], topics: Topic[]): CourseWork[] => {
  const topicIds = topics.filter((topic) => topic.courseId == courseId).map((topic) => topic.topicId)
  const filteredCourseWorks = courseWorks.filter((courseWork) => topicIds.includes(courseWork.topicId))
  return filteredCourseWorks
}

export const createCourseWorkMaterial = async (props:CreateCourseWorkMaterialProps): Promise<CourseWorkMaterial> => {
  const {scheduledDate, driveFiles, linkUrls, courseId, topicId, title, description} = props
  // due date/timeとscheduledtimeをdropした日付から計算する
  var metadata:CourseWork = {
    "state": "DRAFT",
    "title": title,
    "description": description ? description : "",
  }
  if (topicId) {
    metadata["topicId"] = topicId;
  }
  if (driveFiles && driveFiles.length > 0 || linkUrls && linkUrls.length > 0) {
    let driveFileMaterials = [], linkMaterials = []
    if(driveFiles) {
      driveFileMaterials = driveFiles.map((driveFile) => {
        return {
          "driveFile": {
            "driveFile": {
              "id": driveFile.id,
            }
          }
        }
      })
    }
    if(linkUrls) {
      // formでやるとだめだったのでlinkにした https://stackoverflow.com/a/47998194
      linkMaterials = linkUrls.map((linkUrl) => {
        return {
          "link": {
            "url": linkUrl
          }
        }
      })
    }
    metadata["materials"] = driveFileMaterials.concat(linkMaterials)
  }

  if(scheduledDate) { // 予約
    const timestamp = toTimestamp(scheduledDate)
    metadata["scheduledTime"] = timestamp
  } else { // 即配布
    metadata["state"] = "PUBLISHED"
  }
  const response = await axios.post(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWorkMaterials`, metadata)
  return response.data
};

export const deleteCourseWorkMaterial = async (courseId:string, id:string): Promise<AxiosResponse> => {
  const response = await axios.delete(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWorkMaterials/${id}`)
  return response
};

interface CreateCourseWorkBatchProps {
  courseId: string
  topicId:string
  title: string
  description: string
}

export const createCourseWork = async (props:CreateCourseWorkProps): Promise<CourseWork> => {
  var metadata: CourseWork = createCourseWorkMetadata(props);
  const response = await axios.post(`https://classroom.googleapis.com/v1/courses/${props.courseId}/courseWork`, metadata)
  return response.data
};

// 返却準備用に予約なしかつDraftでつくる
export const createCourseWorkBatch = (props:CreateCourseWorkBatchProps, batch: gapi.client.Batch<any>) => {
  var metadata: CourseWork = createCourseWorkMetadataWithNoScheduleAndDraft(props);
  const req = window.gapi.client.request({
    'method': 'POST',
    'path': `https://classroom.googleapis.com/v1/courses/${props.courseId}/courseWork`,
    'body': metadata
  });
  batch.add(req);
  return req
};

function createCourseWorkMetadataWithNoScheduleAndDraft(props:CreateCourseWorkBatchProps) {
  const {topicId, title, description} = props
  
  var metadata: CourseWork = {
    "workType": "ASSIGNMENT",
    "state": "DRAFT",
    "title": title,
    "description": description,
    "maxPoints": 0, // 返却で得点をなしにする。返却はここのみを通る。
  };
  if (topicId) {
    metadata["topicId"] = topicId;
  }

  return metadata;
}


export function createCourseWorkMetadata(props:CreateCourseWorkProps) {
  const {scheduledDate, driveFiles, courseId, topicId, title, readCheck, dueDate, linkUrls, description} = props
  var metadata: CourseWork = {
    "workType": "ASSIGNMENT",
    "state": "DRAFT",
    "title": title,
    "description": description ? description : "",
    "maxPoints": 100, // APIから採点するために必要
  };
  if (topicId) {
    metadata["topicId"] = topicId;
  }
  if (scheduledDate) { // 予約
    const timestamp = toTimestamp(scheduledDate);
    metadata["scheduledTime"] = timestamp;
  } else { // 即配布
    metadata["state"] = "PUBLISHED";
  }

  if (driveFiles && driveFiles.length > 0 || linkUrls && linkUrls.length > 0) {
    let driveFileMaterials = [], linkMaterials = []
    if (driveFiles && driveFiles.length > 0) { // ファイルをドロップ
      driveFileMaterials = driveFiles.map((driveFile) => {
        // FREE_DRAWはclassroomに存在しないので、VIEWに変える
        const shareMode = driveFile.shareMode === CourseWorkShareMode.FREE_DRAW ? CourseWorkShareMode.VIEW : driveFile.shareMode
        return {
          "driveFile": {
            "driveFile": {
              "id": driveFile.id,
            },
            "shareMode": shareMode,
          }
        }
      })
    } 
  
    if(linkUrls) {
      // formでやるとだめだったのでlinkにした https://stackoverflow.com/a/47998194
      linkMaterials = linkUrls.map((linkUrl) => {
        return {
          "link": {
            "url": linkUrl
          }
        }
      })
    }
    metadata["materials"] = driveFileMaterials.concat(linkMaterials)
  }

  if (dueDate) {
    metadata["dueDate"] = {
      "year": dueDate.getUTCFullYear(),
      "month": dueDate.getUTCMonth() + 1,
      "day": dueDate.getUTCDate(),
    };
    metadata["dueTime"] = {
      "hours": dueDate.getUTCHours(),
      "minutes": dueDate.getUTCMinutes(),
      "seconds": dueDate.getUTCSeconds(),
    };
  }
  // https://developers.google.com/classroom/reference/rest/v1/courses.courseWork
  if (readCheck) {
    metadata["workType"] = "MULTIPLE_CHOICE_QUESTION";
    metadata["multipleChoiceQuestion"] = {
      "choices": [
        "読みました"
      ]
    };
    metadata["description"] = `この${HAIFUBUTSU_TOPIC_NAME}には既読確認が設定されています。内容確認後、「読みました」にチェックを付けて「提出」をクリックしてください。`;
  }
  return metadata;
}

const getUpdateCourseWorkMetadata = (eventUpdatableProps:EventUpdatableProps) => {
  var metadata:CourseWork = {}
  const { scheduledTimeDayjs, dueDateTimeDayjs, title, description, noDueDate, maxPoints } = eventUpdatableProps
  if (noDueDate) {
    metadata["dueDate"] = null
    metadata["dueTime"] = null 
  } else if (dueDateTimeDayjs) {
    const newDueDate = dueDateTimeDayjs.toDate()
    metadata["dueDate"] = {
      "year": newDueDate.getUTCFullYear(),
      "month": newDueDate.getUTCMonth()+1,
      "day": newDueDate.getUTCDate(),
    }
    metadata["dueTime"] = {
      "hours": newDueDate.getUTCHours(),
      "minutes": newDueDate.getUTCMinutes(),
      "seconds": newDueDate.getUTCSeconds(),
    }      
  }
  if (scheduledTimeDayjs) {
    const scheduledDate = scheduledTimeDayjs.toDate()
    const timestamp = toTimestamp(scheduledDate)
    metadata["scheduledTime"] = timestamp
  }
  if (title) {
    metadata["title"] = title
  }
  if (description) {
    metadata["description"] = description
  }
  if (maxPoints) {
    metadata["maxPoints"] = maxPoints
  }
  return metadata
}

function removeLastComma(str: string): string {
  const lastIndex = str.lastIndexOf(',');
  
  // コンマが見つからなければ元の文字列を返す
  if (lastIndex === -1) {
    return str;
  }
  
  // 最後のコンマを削除して新しい文字列を返す
  return str.slice(0, lastIndex) + str.slice(lastIndex + 1);
}

const getUpdateMask = (eventUpdatableProps:EventUpdatableProps) => {
  let str = ""
  if(eventUpdatableProps.title){
    str = str.concat("title,")
  }
  if(eventUpdatableProps.description){
    str = str.concat("description,")
  }
  if(eventUpdatableProps.scheduledTimeDayjs){
    str = str.concat("scheduledTime,")
  }
  if(eventUpdatableProps.dueDateTimeDayjs || eventUpdatableProps.noDueDate){
    str = str.concat("dueDate,dueTime,")
  }
  if(eventUpdatableProps.maxPoints){
    str = str.concat("maxPoints,")
  }
  return removeLastComma(str)
}
export const updateCourseWork = async (courseId: string, id: string, eventUpdatableProps:EventUpdatableProps): Promise<CourseWork> => {
  const metadata = getUpdateCourseWorkMetadata(eventUpdatableProps)
  const updateMask = getUpdateMask(eventUpdatableProps)
  const response = await axios.patch(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${id}?updateMask=${updateMask}`, metadata)
  return response.data
};

export const updateCourseWorkMaterial = async (courseId: string, id: string, eventUpdatableProps:EventUpdatableProps): Promise<CourseWork> => {
  const metadata = getUpdateCourseWorkMetadata(eventUpdatableProps)
  const updateMask = getUpdateMask(eventUpdatableProps)
  const response = await axios.patch(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWorkMaterials/${id}?updateMask=${updateMask}`, metadata)
  return response.data
};

export const publishCourseWork = async (courseId: string, id: string): Promise<CourseWork> => {
  var metadata:CourseWork = {}
  metadata["state"] = "PUBLISHED"
    
  const response = await axios.patch(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${id}?updateMask=state`, metadata)
  return response.data
};

export const makeHenkyakuCourseWorkComplete = async (courseId: string, id: string): Promise<CourseWork> => {
  var metadata:CourseWork = {}
  metadata["title"] = `${yyyymmddOnLocal(new Date())}の返却`
  metadata["description"] = ""
    
  const response = await axios.patch(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${id}?updateMask=title,description`, metadata)
  return response.data
};

export const deleteCourseWork = async (courseId:string, id:string): Promise<AxiosResponse> => {
  const response = await axios.delete(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${id}`)
  return response
};

export const batchRequest = async (formData:FormData): Promise<AxiosResponse> => {
  const response = await axios.post('https://classroom.googleapis.com/batch', formData)
  return response
};

// よくできました・再提出
export const gradeAndReturn = async (submission:StudentSubmission, grade: number): Promise<any> => {
  const data = await gradeSubmission(submission, grade)
  if (submission.state == "TURNED_IN") {
    const returnRes = await axios.post(`https://classroom.googleapis.com/v1/courses/${submission.courseId}/courseWork/${submission.courseWorkId}/studentSubmissions/${submission.id}:return`, {})
    return returnRes.data    
  } else {
    return data
  }
};

export const gradeSubmission = async (submission:StudentSubmission, grade: number): Promise<any> => {
  const gradeRes = await axios.patch(`https://classroom.googleapis.com/v1/courses/${submission.courseId}/courseWork/${submission.courseWorkId}/studentSubmissions/${submission.id}?updateMask=draftGrade,assignedGrade`, {
    draftGrade: grade,
    assignedGrade: grade,
  })
  return gradeRes.data
};

export const announceToIndividualStudent = async (courseId:string, text:string, studentId: string, alternateLink: string): Promise<any> => {
  try {
    const response = await axios.post(`https://classroom.googleapis.com/v1/courses/${courseId}/announcements`, {
      text: text,
      assigneeMode: "INDIVIDUAL_STUDENTS",
      individualStudentsOptions: {
        studentIds: [
          studentId
        ]
      },
      // linkとして渡さないとiOSアプリ内で遷移してくれない
      materials: [
        {
          link: {
            url: alternateLink
          }
        }
      ]
    })
    return response
  } catch (error) {
    console.error(error)
  }
};

export const getStudentName = (studentId:string, students: Student[]): string => {
  const student = students?.find((student)=> student.userId == studentId)

  return student?.profile?.name?.fullName
}

export const getSubmissionName = (submission:StudentSubmission): string => {
  // submission.assignedGrade == 0の時があり得るので、そのときにundefinedにならないようにNumber.isIntegerを使う
  const gradeString = Number.isInteger(submission.assignedGrade) && String(submission.assignedGrade) + "点"
  const submissionStateString = switchBySubmission(submission.state, true)
  return gradeString ? gradeString + " " + submissionStateString : submissionStateString
}

export const getSubmissionColor = (submissionState:StudentSubmissionState): string => {
  return switchBySubmission(submissionState, false);
}

function switchBySubmission(submissionState: StudentSubmissionState, isName:boolean) {
  switch (submissionState) {
    case "SUBMISSION_STATE_UNSPECIFIED":
      return isName ? "" : ""
    case "NEW":
      return isName ? "未読" : "#fd001e80"
    case "CREATED":
      return isName ? "未提出" : "#fd001e80"
    case "TURNED_IN":
      return isName ? "提出済み" : "#00000080"
    case "RETURNED":
      return isName ? "OK" : "#03a9f480"
    case "RECLAIMED_BY_STUDENT":
      return isName ? "提出取り下げ" : "#fd001e80"
    default:
      return "";
  }
}

export const shouldShowRating = (submissionState:StudentSubmissionState) => {
  return submissionState === "TURNED_IN"
}

export const replaceResolutionString = (str:string): string => {
  return str?.replace("=s200", "=s1000")
}

export function changeSubmissionThumbnailResolution(submission: StudentSubmission) {
  if (submission.assignmentSubmission.attachments) {
    submission.assignmentSubmission.attachments.forEach((attachment) => {
      if (attachment.driveFile) {
        attachment.driveFile.thumbnailUrl = replaceResolutionString(attachment.driveFile.thumbnailUrl);
      }
    });
  }
}

// https://lh4.googleusercontent.com/r6gKPdTdF6Vy9qKmJJfa6xftLMblPedio5xr1SnUwdiabPmXeJnD27c_QJGLRTfhIw3a_Au94iadwb4=s200
export const changeThumbnailResolution = (submissions:StudentSubmission[]): StudentSubmission[] => {
  submissions.forEach((submission)=> {
    changeSubmissionThumbnailResolution(submission);
  })
  return submissions
}

export const findTheTopicId = (topics:Topic[], topicName:string): string => {
  const topic = topics.find((topic) => topic.name == topicName)
  if(topic){
    return topic.topicId
  } else {
    return null
  }
}

export const createTopic = async (courseId:string, topicName:string): Promise<string> => {
  const response = await axios.post(`https://classroom.googleapis.com/v1/courses/${courseId}/topics`, {
    name: topicName,
  })
  return response.data.topicId
}

export function createBatchRequestArgs(originalArray: DifferenceToRequiredTopics[]) {
  return originalArray.flatMap((item) => {
    const { courseId, difference } = item;
    return difference.map((name) => {
      return {
        'path': `https://classroom.googleapis.com/v1/courses/${courseId}/topics`,
        'method': 'POST',
        'body': {
          'name': name,
        }
      };
    });
  });
}

export const batchCreateTopics = async (diff:DifferenceToRequiredTopics[]): Promise<gapi.client.Request<any>[]> => {
  const batch = gapi.client.newBatch();
  const args = createBatchRequestArgs(diff);
  const requests = args.map(arg => {
    const req = window.gapi.client.request(arg)
    batch.add(req)
    return req
  });
  await batch.then();
  return requests
}

export const toStudentCourseWorkUrl = (submissionAlternateLink:string) => {
  // from: https://classroom.google.com/u/0/c/NDk2MTc4MDE1NDA5/a/NTQ4MTQ5OTgxMTU5/submissions/by-status/and-sort-last-name/student/NTI2NDM3NDQ3ODE1
  // to:   https://classroom.google.com/u/0/c/NDk2MTc4MDE1NDA5/sp/NTI2NDM3NDQ3ODE1/all
  const regex = /\/a\/.*student/i;
  const replaced = submissionAlternateLink.replace(regex, '/sp')
  return `${replaced}/all`
}

export const getMyCourseList = async () => {
  const coursesRes = await axios.get("https://classroom.googleapis.com/v1/courses?teacherId=me&courseStates=ACTIVE")
  const courses: Course[] = coursesRes.data.courses
  return courses
}

export const getCourseList = async () => {
  const coursesRes = await axios.get("https://classroom.googleapis.com/v1/courses")
  const courses: Course[] = coursesRes.data.courses
  return courses
}

export const getStudentList = async (courseId: string) => {
  const studentRes = await axios.get(`https://classroom.googleapis.com/v1/courses/${courseId}/students`)
  const students:Student[] = studentRes.data.students
  return students
}

export const getStudentSubmissionList = async (courseId: string, courseWorkId: string) => {
  const studentSubmissionsRes = await axios.get(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${courseWorkId}/studentSubmissions`)
  const submissions:StudentSubmission[] = studentSubmissionsRes.data.studentSubmissions

  return submissions
}

export const getTurnedInStudentSubmissionList = async (courseId: string, courseWorkId: string) => {
  const studentSubmissionsRes = await axios.get(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${courseWorkId}/studentSubmissions?states=TURNED_IN`)
  const submissions:StudentSubmission[] = studentSubmissionsRes.data.studentSubmissions

  return submissions
}

export type BatchRequests = {
  courseId: string
  request: gapi.client.Request<any>
}

export type InitialDataRequests = {
  topicsRequests?: BatchRequests[];
  courseWorkRequests: BatchRequests[];
  courseWorkMaterialRequests: BatchRequests[];
}

export type StudentInitialDataRequests = {
  courseWorkMaterialRequests: BatchRequests[];
  profileRequest: gapi.client.Request<any>;
  courseWorkRequests: BatchRequests[];
  topicsRequests: BatchRequests[];
}

export async function getInitialData(courseIds: string[]):Promise<InitialDataRequests> {
  const batch = gapi.client.newBatch();
  const courseWorkRequests: BatchRequests[] = addBatchOfGetRequests(courseIds, batch, `/courseWork?courseWorkStates=PUBLISHED&courseWorkStates=DRAFT`);
  const courseWorkMaterialRequests: BatchRequests[] = addBatchOfGetRequests(courseIds, batch, `/courseWorkMaterials?courseWorkMaterialStates=PUBLISHED&courseWorkMaterialStates=DRAFT`);
  await batch.then();
  return {
    courseWorkRequests,
    courseWorkMaterialRequests
  };
}

export async function getStudentInitialData(courseIds: string[]):Promise<StudentInitialDataRequests> {
  const batch = gapi.client.newBatch();
  const courseWorkMaterialRequests: BatchRequests[] = addBatchOfGetRequests(courseIds, batch, `/courseWorkMaterials`);
  const courseWorkRequests: BatchRequests[] = addBatchOfGetRequests(courseIds, batch, `/courseWork`);
  const topicsRequests: BatchRequests[] = addBatchOfGetRequests(courseIds, batch, `/topics`);
  const profileRequest:gapi.client.Request<any> = addProfileRequest(batch);
  await batch.then();
  return { courseWorkMaterialRequests, profileRequest, courseWorkRequests, topicsRequests };
}

export async function getStudentSubmissions(courseWorks: CourseWork[]) {
  const submissionBatch = gapi.client.newBatch();
  const requests = courseWorks.map((courseWork) => {
    const req = window.gapi.client.request({
      'path': `https://classroom.googleapis.com/v1/courses/${courseWork.courseId}/courseWork/${courseWork.id}/studentSubmissions`,
    });
    submissionBatch.add(req);
    return req
  })
  const ret = await submissionBatch.then();
  const submissions = await getAllStudentSubmission(requests)
  return submissions
}

export function addBatchOfGetRequests(courseIds: string[], batch: gapi.client.Batch<any>, pathAfterCourseId: string): BatchRequests[] {
  return courseIds.map((courseId: string) => {
    const req = window.gapi.client.request({
      'path': `https://classroom.googleapis.com/v1/courses/${courseId}${pathAfterCourseId}`,
    });
    batch.add(req);
    return {
      courseId: courseId,
      request: req
    };
  });
}

function addProfileRequest(batch: gapi.client.Batch<any>): gapi.client.Request<any> {
  const req = window.gapi.client.request({
    'path': `https://classroom.googleapis.com/v1/userProfiles/me`,
  });
  batch.add(req);
  return req
}

export const modifyAttachmentToBatch = (studentSubmission: StudentSubmission, attachmentFileId: string, batch:gapi.client.Batch<any>) => {
  const req = window.gapi.client.request({
    'method': 'POST',
    'path': `https://classroom.googleapis.com/v1/courses/${studentSubmission.courseId}/courseWork/${studentSubmission.courseWorkId}/studentSubmissions/${studentSubmission.id}:modifyAttachments`,
    'body': {
      "addAttachments": [
        {
          "driveFile": {
            "id": attachmentFileId,
          },
        }
      ]
    }
  });
  batch.add(req);
  return req
}

export const modifyAttachment = async (studentSubmission: StudentSubmission, attachmentFileId: string) => {
  const response = await axios.post(`https://classroom.googleapis.com/v1/courses/${studentSubmission.courseId}/courseWork/${studentSubmission.courseWorkId}/studentSubmissions/${studentSubmission.id}:modifyAttachments`, {
    "addAttachments": [
      {
        "driveFile": {
          "id": attachmentFileId,
        },
      }
    ]
  })
  return response.data
}

// excalidrawファイルをstudentSubmissionに添付する
export const modifyAttachmentWithExcalidrawLink = async (studentSubmission: StudentSubmission, attachmentFileId: string, origin: string) => {
  const response = await axios.post(`https://classroom.googleapis.com/v1/courses/${studentSubmission.courseId}/courseWork/${studentSubmission.courseWorkId}/studentSubmissions/${studentSubmission.id}:modifyAttachments`, {
    "addAttachments": [
      {
        "link": {
          "url": `${origin}/draw/${attachmentFileId}`,
        },
      },
      // file自体もattachしないと、先生にdrive権限を共有できない
      // courseWorkのフォルダに入れようにも生徒には権限がないので、この方法しかなさそう
      // 一旦attachして直後に削除してもdrive権限は残ることはわかっているけど、先生側の一覧表示ロジックがちょっと複雑になるので現状添付させたままの前提で一覧ロジック書いている
      {
        "driveFile": {
          "id": attachmentFileId,
        },
      }
    ]
  })
  return response.data
}

// publishHenkyaku/runHenkyaku で使用
export const patchStudentSubmission = (submission: StudentSubmission, attachmentFileId: string, batch:gapi.client.Batch<any>) => {
  // const grade = getRandomNumber()
  const grade = 100 // 返却時はcourseWorkのmaxPointsを0にするので、得点は表示されない。固定で100にしておく。z
  const req = window.gapi.client.request({
    'method': 'PATCH',
    'path': `https://classroom.googleapis.com/v1/courses/${submission.courseId}/courseWork/${submission.courseWorkId}/studentSubmissions/${submission.id}?updateMask=draftGrade,assignedGrade`,
    'body': {
      "draftGrade": grade,
      "assignedGrade": grade,
    }
  });
  batch.add(req);
  return req
}

export const patchStudentSubmissionGrade = (submission: StudentSubmission, grade:number, batch:gapi.client.Batch<any>) => {
  const req = window.gapi.client.request({
    'method': 'PATCH',
    'path': `https://classroom.googleapis.com/v1/courses/${submission.courseId}/courseWork/${submission.courseWorkId}/studentSubmissions/${submission.id}?updateMask=draftGrade,assignedGrade`,
    'body': {
      "draftGrade": grade,
      "assignedGrade": grade,
    }
  });
  batch.add(req);
  return req
}


export const returnStudentSubmission = (submission: StudentSubmission, batch:gapi.client.Batch<any>) => {
  const req = window.gapi.client.request({
    'method': 'POST',
    'path': `https://classroom.googleapis.com/v1/courses/${submission.courseId}/courseWork/${submission.courseWorkId}/studentSubmissions/${submission.id}:return`,
  });
  batch.add(req);
  return req
}

export const turnInStudentSubmission = async (submission: StudentSubmission) => {
  const response = await axios.post(`https://classroom.googleapis.com/v1/courses/${submission.courseId}/courseWork/${submission.courseWorkId}/studentSubmissions/${submission.id}:turnIn`)
  return response.data
}

export const reclaimStudentSubmission = async (submission: StudentSubmission) => {
  const response = await axios.post(`https://classroom.googleapis.com/v1/courses/${submission.courseId}/courseWork/${submission.courseWorkId}/studentSubmissions/${submission.id}:reclaim`)
  return response.data
}

function getRandomNumber(): number {
  const min = 60;
  const max = 100;
  return Math.floor(Math.random() * (max - min + 1)) + min;
}

export const getAllCourseWorks = async (courseWorkRequests:BatchRequests[]) => {
  const cwsOfCws:CourseWork[][] = await Promise.all(courseWorkRequests.map(async (req:BatchRequests) => {
    const value = await req.request.then()
    return value.result.courseWork
  }))
  const cws:CourseWork[] = cwsOfCws.flat().filter(Boolean) // undefined消し
  return cws
}

export const getAllTopics = async (topicsRequests:BatchRequests[]) => {
  const topicsOfTopics:Topic[][] = await Promise.all(topicsRequests.map(async (req:BatchRequests) => {
    const value = await req.request.then()
    return value.result.topic
  }))
  const topics:Topic[] = topicsOfTopics.flat().filter(Boolean) // undefined消し
  return topics
}

export const getAllStudentSubmission = async (studentSubmissionRequests:gapi.client.Request<any>[]) => {
  const mapper = studentSubmissionRequests.map(async (req:gapi.client.Request<any>) => {
    const value = await req.then()
    return value.result.studentSubmissions
  })
  const submissionsOfSubmissions:StudentSubmission[][] = await Promise.all(mapper)
  const submissions:StudentSubmission[] = submissionsOfSubmissions.flat().filter(Boolean) // undefined消し
  return submissions
}

export const reloadStudentSubmission = async (submission:StudentSubmission) => {
  const res = await axios.get(`https://classroom.googleapis.com/v1/courses/${submission.courseId}/courseWork/${submission.courseWorkId}/studentSubmissions/${submission.id}`)
  const reloadedSubmission: StudentSubmission = res.data
  return reloadedSubmission
}

export const getMyStudentSubmissions = async (courseId:string, courseWorkId:string) => {
  const res = await axios.get(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${courseWorkId}/studentSubmissions?userId=me`)
  const submissionsData = res.data
  return submissionsData.studentSubmissions as StudentSubmission[]
}

export const getMyStudentSubmission = async (courseId:string, courseWorkId:string) => {
  const submissions = await getMyStudentSubmissions(courseId, courseWorkId)
  return submissions[0]
}

export const getCourseWork = async (courseId:string, courseWorkId:string) => {
  const res = await axios.get(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWork/${courseWorkId}`)
  const courseWork: CourseWork = res.data
  return courseWork
}

export async function getMyProfile() {
  const res = await axios.get(`https://classroom.googleapis.com/v1/userProfiles/me`)
  return res.data
}

export const listCoursesByEmail = (email: string, batch:gapi.client.Batch<any>) => {
  const req = window.gapi.client.request({
    'method': 'GET',
    'path': `https://classroom.googleapis.com/v1/courses?studentId=${email}&courseStates=ACTIVE`, // getMyCourseListに合わせてACTIVE限定にする。ACTIVEのやつじゃないと必要なtopicsが作成されてない可能性がある。
  });
  batch.add(req);
  return req
}

export const getUserProfileByEmail = (email: string, batch:gapi.client.Batch<any>) => {
  const req = window.gapi.client.request({
    'method': 'GET',
    'path': `https://classroom.googleapis.com/v1/userProfiles/${email}`,
  });
  batch.add(req);
  return req
}

export const listCourseWorks = async (courseId: string) => {
  const res = await axios.get(`https://classroom.googleapis.com/v1/courses/${courseId}/courseWork`)
  const courseWorks:CourseWork[] = res.data.courseWork

  return courseWorks
}
