import { GoogleSpreadsheetRow } from "google-spreadsheet";

// NodeModelをクラスにして、rowでspreadsheetのrowを持つようにして、作成・更新・削除メソッドを生やす
export class NodeModel {
  id: number;
  parent: number;
  droppable?: boolean;
  text: string;
  data?: any;
  index?: number;
  row: GoogleSpreadsheetRow<Record<string, any>>;

  // rowがないパターンをつくりたい
  // 考えられるパターン
  // 1. 作成直後でまだスプシに存在しないパターン
  // 2. courseをmergeCoursesToNodeModelで変換した時に作成するもの
  // 3. 普通にspreadsheetから初期化するもの
  constructor(row:GoogleSpreadsheetRow<Record<string, any>>) {
    this.row = row
    this.id = Number(row.get('id'))
    this.parent = Number(row.get('parent'))
    this.text = row.get('text')
    const droppableStr = row.get('droppable') // spreadsheet上はbooleanでもstringとして取得される
    this.droppable = droppableStr && droppableStr.toLowerCase() === "true"
    this.index = Number(row.get('index'))
  }

  // rowがないパターンではrowを新規作成にしたい
  async delete() {
    return await this.row.delete()
  }

  getRowKV() {
    return {
      id: this.id,
      parent: this.parent,
      droppable: this.droppable,
      text: this.text,
      data: this.data,
      index: this.index,
    }
  }
  async update() {
    this.row.assign(this.getRowKV())
    return await this.row.save()
  }

  diffExistInParentOrIndex(nodeModelToCompare: NodeModel) {
    return this.parent !== nodeModelToCompare.parent || this.index !== nodeModelToCompare.index
  }
}