import axios from "axios";
import { CallbackDoc } from "@sakawateam/react-google-drive-picker/dist/typeDefs";
import { ConfigJson } from "src/contexts/domain-data-context";
import { fileToBase64 } from "src/utils/file-to-base64";

interface DriveUploadResponseData {
  kind?: string;
  id?: string;
  name?: string;
  mimeType?: string;
}

export const upload = async (file:File, folderId?:string): Promise<DriveUploadResponseData> => {
  var metadata = {
      "name": file.name,
      "mimeType": file.type,
  };
  if (folderId) {
    metadata["parents"] = [folderId]
  }
  
  var form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(metadata)], { type: 'application/json' }));
  form.append('file', file);
  
  const createResponse = await axios.post("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true", form)
  const data:DriveUploadResponseData = createResponse.data
  if (!folderId) { // folderIdない場合はここで終了
    return data
  }
  const fileId = data.id

  // nameとparentの設定がcreateではできなかったので、updateで行う
  // https://developers.google.com/drive/api/reference/rest/v3/files/update
  const updateBody = {
    "name": file.name,
  }
  // metadataのみのupdate用endpoint
  return await addParents(fileId, folderId, updateBody);
};

export const list = async (driveId:string) => {
  // https://www.googleapis.com/auth/drive 権限が必要
  const response = await axios.get(`https://www.googleapis.com/drive/v3/files?q='${driveId}'%20in%20parents`)
  const data = response.data

  return data
};

interface HenkyakuDriveFiles {
  files: HenkyakuDriveFile[]
}

interface HenkyakuDriveFile {
  thumbnailLink: string
  size: string
  id: string
  name: string
  createdTime: string
  modifiedTime: string
  appProperties: {
    courseWorkId: string
    studentId: string
  }
}
// propertiesとappPropertiesが別物なので注意
// listでのfields=の指定方法
// https://developers.google.com/drive/api/guides/fields-parameter
export const listByCourseWorkId = async (courseWorkId:string) => {
  const response = await axios.get(`https://www.googleapis.com/drive/v3/files?q=appProperties%20has%20%7B%20key%3D%27courseWorkId%27%20and%20value%3D%27${courseWorkId}%27%20%7D&fields=files(id%2Cname%2CcreatedTime%2CmodifiedTime%2Csize%2CthumbnailLink%2CappProperties)`)
  const data: HenkyakuDriveFiles = response.data

  return data
};

export const listHenkyakuFoldersByCourseWorkId = async (courseWorkId:string) => {
  const response = await axios.get(`https://www.googleapis.com/drive/v3/files?q=appProperties%20has%20%7B%20key%3D%27courseWorkId%27%20and%20value%3D%27${courseWorkId}%27%20%7D%20and%20mimeType%3D'application%2Fvnd.google-apps.folder'&fields=files(id%2Cname%2CcreatedTime%2CmodifiedTime%2Csize%2CthumbnailLink%2CappProperties)`)
  const data: HenkyakuDriveFiles = response.data

  return data
};

export interface listWithFileIdsResult {
  id: string;
  name: string;
  thumbnailLink: string;
  mimeType: string;
}
export const upconvertIconUrl = (url?:string) => {
  if (url) {
    return url.replace("16", "64")
  }
}

export const listWithFileIds = async (docs:CallbackDoc[]) => {
  if (docs.length < 1) {
    return []
  }
  const batch = gapi.client.newBatch();
  docs.map((doc) => {
    const req = window.gapi.client.request({
      'method': 'GET',
      'path': `https://www.googleapis.com/drive/v3/files/${doc.id}?fields=id,name,thumbnailLink,mimeType`,
    });  
    batch.add(req);
  })
  const resMap = await batch.then();
  const results:listWithFileIdsResult[] = Object.keys(resMap.result).map((key)=> resMap.result[key].result)
  const resultsMap = results.map((result) => {
    const myDoc = docs.find((doc) => doc.id == result.id)
    const thumbnail = result.thumbnailLink ? result.thumbnailLink : ""

    return {
      id: result.id,
      name: result.name,
      mimeType: result.mimeType,
      thumbnailLink: thumbnail
    }
  })
  return resultsMap.filter( Boolean ); // undefined消し = リクエストエラーは無視
};

export async function addParents(fileId: string, folderId: string, updateBody: { name?: string; }) {
  const updateResponse = await axios.patch(`https://www.googleapis.com/drive/v3/files/${fileId}?addParents=${folderId}`, updateBody);

  return updateResponse.data;
}

export function addParentsToBatch(fileId: string, folderId: string, myBatch: gapi.client.Batch<any>) {
  const args = {
    'method': 'PATCH',
    'path': `https://www.googleapis.com/drive/v3/files/${fileId}?addParents=${folderId}`,
    'body': {}
  }
  const patchRequest = window.gapi.client.request(args);
  myBatch.add(patchRequest);
  return patchRequest
}

export interface AddFilePropertiesProps {
  studentId: string
  courseWorkId: string
}

export async function addFileProperties(fileId:string, properties: object) {
  var body = {
    "appProperties": properties,
  };
  let path = `https://www.googleapis.com/drive/v3/files/${fileId}`
  const response = await axios.patch(path, body);
  return response.data
}

export function addFilePropertiesToBatch(fileId: string, properties: AddFilePropertiesProps, myBatch: gapi.client.Batch<any>) {
  const args = {
    'method': 'PATCH',
    'path': `https://www.googleapis.com/drive/v3/files/${fileId}`,
    'body': {
      "appProperties": properties, // propertiesとappPropertiesが別物なので注意
    }
  }
  const patchRequest = window.gapi.client.request(args);
  myBatch.add(patchRequest);
  return patchRequest
}

export function recursiveListFiles(driveId:string, list:any[], callback:any, pageToken?:string) {
  // var request = gapi.client.drive.files.list(reqParams);
  let path = `https://www.googleapis.com/drive/v3/files?q='${driveId}'%20in%20parents`
  if (pageToken) {
    path = path + `&pageToken=${pageToken}`
  }
  var request = gapi.client.request({
    'path': path
  })
  request.execute(function(resp) {
    const anyResp = resp as any
    const newList = [...list, ...anyResp.files]
    callback(newList)
    if(anyResp.nextPageToken) {
      recursiveListFiles(driveId, newList, callback, anyResp.nextPageToken);
    }
  });
}

export async function getCurrentVersion(fileId:string) {
  let path = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=version`
  const response = await axios.get(path);
  return response.data?.version
}

export const getImageMediaMetadataSize = async (fileId:string) => {
  const res = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=imageMediaMetadata,mimeType`)
  return {
    width: res.data?.imageMediaMetadata?.width,
    height: res.data?.imageMediaMetadata?.height,
    mimeType: res.data?.mimeType,
  }
};

export async function getCurrentVersionAndThumbnailLink(fileId:string) {
  let path = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=version,thumbnailLink,mimeType`
  // let path = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=version,thumbnailLink,mimeType,appProperties`
  const response = await axios.get(path);
  return {
    version: response.data?.version,
    thumbnailLink: response.data?.thumbnailLink,
    mimeType: response.data?.mimeType,
    fileId: fileId,
  }
}

export async function getAppProperties(fileId:string) {
  let path = `https://www.googleapis.com/drive/v3/files/${fileId}?fields=appProperties`
  const response = await axios.get(path);
  return {
    appProperties: response.data?.appProperties,
  }
}

export const getMimeType = async (fileId:string) => {
  const res = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=mimeType`)
  return {
    mimeType: res.data.mimeType,
  }
};

export const getMimeTypeAndWebContentLink = async (fileId:string) => {
  const res = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?fields=mimeType,webContentLink`)
  return {
    mimeType: res.data.mimeType,
    webContentLink: res.data.webContentLink,
  }
};

export async function copyFile(fileId:string, parentFolderId:string) {
  const createResponse = await axios.post(`https://www.googleapis.com/drive/v3/files/${fileId}/copy`, {
    'parents': [parentFolderId],
  })
  return createResponse.data
}

export function copyToBatch(fileId:string, parentFolderId:string, myBatch: gapi.client.Batch<any>) {
  const req = window.gapi.client.request({
    'path': `https://www.googleapis.com/drive/v3/files/${fileId}/copy`,
    'method': 'POST',
    'body': {
      'parents': [parentFolderId],
    }
  });
  myBatch.add(req);
  return req
}

export function createPermission(fileId:string, emailAddress:string, myBatch: gapi.client.Batch<any>) {
  const req = window.gapi.client.request({
    'path': `https://www.googleapis.com/drive/v3/files/${fileId}/permissions`,
    'method': 'POST',
    'body': {
      'type': 'user',
      'role': 'writer',
      'emailAddress': emailAddress,
    }
  });
  myBatch.add(req);
  return req
}

export const removeFile = async (fileId:string) => {
  const res = await axios.delete(`https://www.googleapis.com/drive/v3/files/${fileId}`)
  return res
};

export async function createHenkyakuFolder(name: string, courseWorkId: string, parendFolderId: string) {
  const fileMetadata = {
    name: name,
    mimeType: 'application/vnd.google-apps.folder',
    parents: [parendFolderId],
    appProperties: {
      courseWorkId: courseWorkId,
    }
  };
  const createResponse = await axios.post(`https://www.googleapis.com/drive/v3/files`, fileMetadata)
  return createResponse.data
}

export async function createGoogleApplicationFile(app:string) {
  const fileMetadata = {
    mimeType: `application/vnd.google-apps.${app}`,
  };
  const createResponse = await axios.post(`https://www.googleapis.com/drive/v3/files?fields=id,name,mimeType,webContentLink,webViewLink,thumbnailLink`, fileMetadata)
  const createData = createResponse.data as gapi.client.drive.File
  return createData
}

export async function updateFolderName(folderId: string, newName: string) {
  const updateResponse = await axios.patch(`https://www.googleapis.com/drive/v3/files/${folderId}`, {
    name: newName,
  });

  return updateResponse.data;
}

export async function findOrCreateConfigJson(): Promise<ConfigJson> {
  const response = await axios.get(`https://www.googleapis.com/drive/v3/files?spaces=appDataFolder`)
  const data = response.data
  if (data.files.length < 1) {
  } else {
    const configJson = data.files.find((file) => {
      return file.name === "config.json" && file.mimeType === "application/json"
    })
    if (configJson) {
      const res = await axios.get(`https://www.googleapis.com/drive/v3/files/${configJson.id}?alt=media`)
      const body = res.data
      return {
        fileId: configJson.id,
        body: body,
      }
    }
  }
  // config.json作成する
  // https://stackoverflow.com/a/67327933/872176
  const body = {
    appDataSheetId : null,
  }
  const fileMetadata = {
    name: "config.json",
    mimeType: 'application/json',
    parents: ['appDataFolder'],
  };
  const form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(fileMetadata)], {type: 'application/json'}));
  form.append('file', new Blob([JSON.stringify(body)], {type: 'application/json'}));

  const createResponse = await axios.post("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true", form)
  const createResponseData:DriveUploadResponseData = createResponse.data

  return {
    fileId: createResponseData.id,
    body: body,
  }
}

export async function findOrCreateAppDataSheet(configJsonFileId: string, configJsonBody: {}) {
  const res = await axios.get(`https://www.googleapis.com/drive/v3/files/${configJsonFileId}?alt=media`)
  const sheetId = res.data.appDataSheetId
  if (sheetId) {
    try { // sheetIdのファイルが存在しないパターン
      const getSheetRes:gapi.client.drive.File = await axios.get(`https://www.googleapis.com/drive/v3/files/${sheetId}`)
      return sheetId
    } catch (error) {
      if (error.response?.status === 404) {
        // 404の場合は再作成にすすむ
      } else {
        return sheetId
      }
    }
  }
  
  // sheet作成する
  const fileMetadata = {
    name: "[Do not modify]ClassroomAID data",
    mimeType: 'application/vnd.google-apps.spreadsheet',
  };
  const createResponse = await axios.post(`https://www.googleapis.com/drive/v3/files`, fileMetadata)
  // config.jsonを更新する
  await updateConfigJson({
    appDataSheetId : createResponse.data.id,
  }, configJsonFileId)
  return createResponse.data.id
}

// todo: nodeModelSheetIdが上書きされないロジック作成する
export const updateConfigJson = async (body:any, fileId:string) => {
  const updateResponseData: DriveUploadResponseData = await updateJsonFile("config.json", body, fileId);
  return updateResponseData.id
}

export async function updateJsonFile(fileName: string, body: any, fileId: string, base64EncodedThumbnailImage?: string) {
  const fileMetadata:gapi.client.drive.File = {
    name: fileName,
    mimeType: 'application/json',
  };
  if (base64EncodedThumbnailImage) {
    const objToAssign:gapi.client.drive.File = {
      contentHints: {
        thumbnail: {
          image: base64EncodedThumbnailImage,
          mimeType: "image/png"
        }
      }
    }
    Object.assign(fileMetadata, objToAssign)
  }
  const form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
  form.append('file', new Blob([JSON.stringify(body)], { type: 'application/json' }));

  const updateResponse = await axios.patch(`https://www.googleapis.com/upload/drive/v3/files/${fileId}?uploadType=multipart&supportsAllDrives=true`, form);
  const updateResponseData: DriveUploadResponseData = updateResponse.data;
  return updateResponseData;
}

export async function downloadJson(fileId: string) {
  const res = await axios.get(`https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`)
  const data = res.data
  return data
}

// バイナリデータとしてダウンロードするにはjsonとはリクエスト方法が異なる
export const fetchImageToBase64 = async (fileId:string) => {
  const imageUrl = `https://www.googleapis.com/drive/v3/files/${fileId}?alt=media`
  const response = await axios.get(imageUrl, {
    responseType: 'arraybuffer', // バイナリデータとして取得
  });
  const contentType = response.headers['content-type']
  // ArrayBufferからBlobを作成
  const blob = new Blob([response.data], { type: contentType });
  // base64に変換
  const base64 = await fileToBase64(blob).catch((error) => {console.error(error)})
  return {
    base64: base64,
    contentType: contentType,
  }
};

export const createExcalidrawJsonFile = async (fileName: string, body:any): Promise<DriveUploadResponseData> => {
  const fileMetadata = {
    name: fileName,
    mimeType: 'application/vnd.excalidraw+json',
    // appProperties: {
    //   excalidrawFile: true,
    // }
  };

  var form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
  form.append('file', new Blob([JSON.stringify(body)], { type: 'application/json' }));
  
  const createResponse = await axios.post("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true", form)
  const data:DriveUploadResponseData = createResponse.data
  return data
};

export const createEmptyExcalidrawReferenceFile = async (): Promise<DriveUploadResponseData> => {
  const fileMetadata = {
    name: "CourseWorkLinkDataFile",
    mimeType: 'application/json',
    // appProperties: {
    //   excalidrawFile: true,
    // }
  };

  var form = new FormData();
  form.append('metadata', new Blob([JSON.stringify(fileMetadata)], { type: 'application/json' }));
  form.append('file', new Blob([JSON.stringify({})], { type: 'application/json' }));
  
  const createResponse = await axios.post("https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart&supportsAllDrives=true", form)
  const data:DriveUploadResponseData = createResponse.data
  return data
};