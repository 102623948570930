import { createContext, useContext, useEffect, useState } from "react"
import { QueryKey, useMutation, UseMutationResult, useQuery, useQueryClient } from "@tanstack/react-query"
import { Course, StudentSubmission, Topic } from 'src/types/googleClassroom';
import { getAllTopics, differenceToRequiredTopics } from "src/lib/topic-model";
import * as CourseModel from 'src/lib/course-model'
import { getCoursesNotExistOnNodeModels, getNodeModelsNotExistOnCourses, getNodeModelsWithCourseNameDiffAssigned, getNodeModelsWithSheets } from 'src/lib/group-manager';
import { useProgress } from "../hooks/use-progress"
import { useBeforeunload } from 'react-beforeunload';
import { addBatchOfGetRequests, batchCreateTopics, BatchRequests, getInitialData, gradeSubmission, InitialDataRequests } from "src/lib/classroom-api-client";
import { CourseWorkAndCourseId, Event, EventUpdatableProps, UnsavedEventData } from 'src/lib/event';
import { EventListFactory } from "src/lib/factory/event-list-factory";
import { findOrCreateConfigJson, findOrCreateAppDataSheet, updateConfigJson, createGoogleApplicationFile } from "src/lib/drive-api-client";
import { GoogleSpreadsheetClient, SheetType } from "src/lib/google-spreadsheet-client";
import { NodeModel as NodeModelClass} from "src/components/tree-view/node-model";
import { mergeCoursesToNodeModel } from "src/lib/json-converter";
import { NodeModel } from "src/components/tree-view/types";
import { useSession } from "next-auth/react";
import _ from 'lodash'
import { getAllCourseIdsUnderAResource } from "../hooks/use-resources";
import { GroupWithSheets } from "src/lib/event-data/group-with-sheets";
import { Resource } from "src/types/resource";
import { GoogleSpreadsheetRow } from "google-spreadsheet";

type InitialData = {
  courses: CourseModel.PrinthubFetchedCourse[]
  events: Event[]
}

interface MutateEventProps {
  event: Event
  eventUpdatableProps: EventUpdatableProps
}

interface CreateEventsProps {
  newEventData:UnsavedEventData
  resources:Resource[]
}

export interface ConfigJson {
  fileId: string
  body: {
    appDataSheetId: string,
    realtimeReloadInterval?: number,
  }
}

const getConfigJson = async ({ queryKey }):Promise<ConfigJson> => {
  const configJson = await findOrCreateConfigJson()
  return configJson
}

const getSheetClient = async (accessToken: string, configJsonFileId:string, configJsonBody:{} ) => {
  const sheetId = await findOrCreateAppDataSheet(configJsonFileId, configJsonBody)
  const client = await GoogleSpreadsheetClient.build(sheetId, accessToken)
  return client
}
async function initSheetClient({ queryKey }):Promise<GoogleSpreadsheetClient> {
  const [_key, { accessToken, configJsonFileId, configJsonBody }] = queryKey
  const sheetClient = await getSheetClient(accessToken, configJsonFileId, configJsonBody)
  return sheetClient
}

async function getCourses({ queryKey }):Promise<CourseModel.PrinthubFetchedCourse[]> {
  const [_key] = queryKey
  const courses = await CourseModel.list()

  return courses
}

async function fetchEvents(courses):Promise<Event[]> {
  const courseIds = courses?.map(course => course.id)
  let { courseWorkRequests, courseWorkMaterialRequests }:InitialDataRequests  = await getInitialData(courseIds);
  
  // デフォルトでtopicを付与しない仕様に変更するため、コメントアウトする
  // ({ courseWorkRequests, studentsRequests, topics } = await createRequiredTopics(topicsRequests, courses, courseIds, courseWorkRequests, studentsRequests));

  const events = await EventListFactory.list(courseWorkRequests, courseWorkMaterialRequests)
  return events
}

async function fetchStudentsAndMergeWithCourses(courses):Promise<Course[]> {
  const courseIds = courses?.map(course => course.id)
  const batch = gapi.client.newBatch();
  const studentsRequests: BatchRequests[] = addBatchOfGetRequests(courseIds, batch, `/students`);
  await batch.then();

  const nextPageBatch = gapi.client.newBatch();
  const nextPageRequests:BatchRequests[] = await Promise.all(studentsRequests.map(async (studentsRequest) => {
    const response = await studentsRequest.request.then()
    if (response.result.nextPageToken) {
      const req = window.gapi.client.request({
        'path': `https://classroom.googleapis.com/v1/courses/${studentsRequest.courseId}/students?pageToken=${response.result.nextPageToken}`,
      });
      nextPageBatch.add(req);
      return {
        request: req,
        courseId: studentsRequest.courseId
      }
    }
  }))
  if (nextPageRequests.filter( Boolean ).length > 0) { // 2ページ目あるかチェック
    await nextPageBatch.then(); // 2ページ目がないと落ちる
  }
  
  const allStudentRequests:BatchRequests[] = [studentsRequests, nextPageRequests].flat().filter( Boolean );
  courses.forEach( async (course) => {
    const studentsRequests = allStudentRequests.filter((req) => req.courseId == course.id)
    if (studentsRequests) {
      const allNewStudents = await Promise.all(studentsRequests.map(async (studentsRequest) => {
        const response = await studentsRequest.request.then()
        const newStudents = response.result.students || []
        return newStudents
      }))
      course.students = allNewStudents.flat()
    }
  })
  return courses
}

async function fetchNodeModelList(sheetClient) {
  const treeData = await getNodeModelsWithSheets(sheetClient)
  return treeData
}

// topicsが存在しないcourseを探して、あればそのtopicを作成するロジック
async function createRequiredTopics(topicsRequests:BatchRequests[], courses: CourseModel.PrinthubFetchedCourse[], courseIds: string[], courseWorkRequests: BatchRequests[], studentsRequests: BatchRequests[]) {
  let topics = await getAllTopics(topicsRequests);
  const diff = differenceToRequiredTopics(courses, topics);
  if (diff.length > 0) {
    const requests = await batchCreateTopics(diff);
    const reInitialDataRequest = await getInitialData(courseIds);
    topicsRequests = reInitialDataRequest.topicsRequests;
    courseWorkRequests = reInitialDataRequest.courseWorkRequests;
    // studentsRequests = reInitialDataRequest.studentsRequests;
    topics = await getAllTopics(topicsRequests);
  }
  return { courseWorkRequests, studentsRequests, topics };
}

interface MutateSubmissionGradeProps {
  submission:StudentSubmission
  grade: number
}

// https://ja.react.dev/learn/scaling-up-with-reducer-and-context
interface DomainDataContextValue {
  courses: CourseModel.PrinthubFetchedCourse[]
  courseIsLoading: boolean
  topicIsLoading: boolean
  nodeModels: NodeModelClass[]
  nodeModelIsLoading: boolean
  events: Event[]
  eventIsLoading: boolean
  updateNodeModels: UseMutationResult<void, Error, NodeModelClass[], unknown>
  updateNodeModel: UseMutationResult<void, Error, NodeModelClass, unknown>
  addNodeModel: UseMutationResult<GoogleSpreadsheetRow<Record<string, any>>, Error, NodeModel, unknown>
  deleteNodeModels: UseMutationResult<any[], Error, NodeModelClass[], unknown>
  reloadEvents: () => Promise<void>
  sheetClient: GoogleSpreadsheetClient
  eventsWithGroupEvents: Event[]
  mutateSubmissionGrade: UseMutationResult<any, Error, MutateSubmissionGradeProps, unknown>
  configJson: ConfigJson
  mutateRealtimeReloadInterval: UseMutationResult<string, Error, number, unknown> 
  mutateEvent: UseMutationResult<Event[], Error, MutateEventProps, unknown> 
  createEvents: UseMutationResult<Event[], Error, CreateEventsProps, unknown>
  deleteEvents: UseMutationResult<Event, Error, Event, unknown>
  createGoogleAppFile: UseMutationResult<gapi.client.drive.File, Error, string, unknown>
  // reloadEvents: () => Promise<void>;
  // bulkReturn: BulkReturnType;
}
export const DomainDataContext = createContext<DomainDataContextValue|null>(null);

export function DomainDataProvider ({ children }) {
  const { startProgress, stopProgress } = useProgress()
  const [reloadingEvents, setReloadingEvents] = useState<boolean>(false)
  const [writingEvents, setWritingEvents] = useState<boolean>(false)
  const { data: session } = useSession()
  const accessToken = (session as any)?.accessToken

  useBeforeunload((event) => {
    if (writingEvents) { // 遷移時に警告
      event.preventDefault();
    }
  });
  
  const {
    data: configJson,
    error: configJsonError,
    isLoading: configJsonIsLoading,
    isError: configJsonIsError
  } = useQuery({
    queryKey: ['configJson'],
    queryFn: getConfigJson,
    enabled: !!accessToken
  })

  const configJsonFileId = configJson?.fileId
  const configJsonBody = configJson?.body
  const {
    data: sheetClient,
    isLoading: sheetClientIsLoading
  } = useQuery({
    queryKey: ['initSheetClient', {accessToken, configJsonFileId, configJsonBody}],
    queryFn: initSheetClient,
    enabled: !!configJsonFileId && !!configJsonBody
  })

  const {
    data: courses,
    isLoading: coursesAreLoading
  } = useQuery({
    queryKey: ['getCourses'],
    queryFn: getCourses,
    enabled: !!accessToken
  })

  const {
    status,
    // fetchStatus,
    data: events,
    isLoading: eventsAreLoading
  } = useQuery({
    queryKey: ['events'],
    queryFn: async () => {
      const data = await fetchEvents(courses)
      return data
    },
    enabled: !!courses
  })
  const courseId = courses && courses?.[0]?.id
  const eventId = events && events?.[0]?.id
  
  const nodeModelBaseQueryKey = 'nodeModels'
  const {
    // status,
    // fetchStatus,
    data: nodeModels,
    isLoading: myNodeModelIsLoading
  } = useQuery({
    queryKey: [nodeModelBaseQueryKey],
    queryFn: async () => {
      const data = await fetchNodeModelList(sheetClient)
      return data
    },
    enabled: !!sheetClient
  })

  const queryClient = useQueryClient()
  async function checkDiffBetweenCoursesAndNodeModel(courses:Course[],nodeModels:NodeModelClass[], sheetClient: GoogleSpreadsheetClient) {
    const treeData = nodeModels
    
    // nodeModelにないcoursesをチェックし、作成する
    const coursesNotExistOnNodeModels = getCoursesNotExistOnNodeModels(treeData, courses)
    const nodeModelsNotExistOnCourses = getNodeModelsNotExistOnCourses(treeData, courses)
    const nodeModelsWithOldCourseName = getNodeModelsWithCourseNameDiffAssigned(treeData, courses)
    if (coursesNotExistOnNodeModels.length < 1 && nodeModelsNotExistOnCourses.length < 1 && nodeModelsWithOldCourseName.length < 1) {
      return treeData
    }
    const newRowValues = coursesNotExistOnNodeModels.map((course) => {
      const rowValue = {
        id: Number(course.id),
        parent: 0,
        droppable: false,
        text: course.name,
      }
      return rowValue
    })
    if (newRowValues && newRowValues.length > 0) { // 
      const rows = await sheetClient.addNodeModels(newRowValues) // 1rowずつ作成をループすると上書きされるのが怖い(insert:trueで上書きされるのかどうかは未確認)ので、一括作成
    }
    // nodeModelにだけ存在しているcourseを削除
    if (nodeModelsNotExistOnCourses && nodeModelsNotExistOnCourses.length > 0) {
      await Promise.all(nodeModelsNotExistOnCourses.map(async (nodeModel) => {
        await nodeModel.delete()
      }))
    }
    // 名前を変更
    if (nodeModelsWithOldCourseName && nodeModelsWithOldCourseName.length > 0) {
      await Promise.all(nodeModelsWithOldCourseName.map(async (nodeModel) => {
        await nodeModel.update()
      }))
    }
    await queryClient.invalidateQueries({ queryKey: [nodeModelBaseQueryKey] })
    return []
  }

  const {
    // data: eventsWithGroupEvents,
    isLoading: checkingDiffBetweenCoursesAndNodeModel
  } = useQuery({
    queryKey: ["checkDiffBetweenCoursesAndNodeModel"],
    queryFn: async () => {
      const data = await checkDiffBetweenCoursesAndNodeModel(courses, nodeModels, sheetClient)
      return data
    },
    enabled: !!courseId && !!sheetClient && !!nodeModels
  })

  const {
    data: coursesWithStudents,
    isLoading: courseIsUpdatingWithStudents
  } = useQuery({
    queryKey: ["coursesWithStudents"],
    queryFn: async () => {
      const data = await fetchStudentsAndMergeWithCourses(courses)
      return data
    },
    enabled: !!courseId
  })

  function replacer(key, value) {
    // TypeError: Converting circular structure to JSONになるkeyをGoogleSpreadsheetClientから取り除く
    // これらがなくなってもクラスの動きとしては問題なさそうだった
    if (key === "_spreadsheet" || key === "_worksheet") {
      return undefined
    }          
    return value;
  }

  const {
    data: eventsWithGroupEvents,
    isLoading: eventsAreUpdatingWithGroupEvents
  } = useQuery({
    queryKey: ["eventsWithGroupEvents", { events, sheetClient }],
    queryFn: async ({ queryKey }) => {
      const [_key, {events, sheetClient} ] = queryKey as any // eventsをinvalidateしたときに反映させるためにeventsはqueryKeyとして渡す
      const data = await EventListFactory.mergeWithGroupEvents(events, sheetClient)
      return data
    },
    // https://tanstack.com/query/latest/docs/framework/react/reference/useQuery#usequery
    // sheetClientをそのままqueryKeyとして渡すと循環参照している関係でTypeError: Converting circular structure to JSONになる
    // これを回避するために独自のクエリーキーをハッシュ化するロジックを指定する
    // JSON.stringifyの第２引数で特定のキーを取り除く関数を渡す
    queryKeyHashFn: (queryKey: QueryKey) => {
      const [_key, {events, sheetClient} ] = queryKey as any
      // https://stackoverflow.com/a/9653082/872176
      // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/JSON/stringify#using_a_function_as_replacer
      return JSON.stringify(queryKey, replacer)
    },
    enabled: !!eventId && !!sheetClient,
    placeholderData: events,
  })

  const updateNodeModels = useMutation({
    mutationFn: (allTreeData:NodeModelClass[]) => { // (リクエスト数減らすため)全クリアから全作成するので、全件渡す必要あり
      const clearAndCreateRows = async () => {
        const rowObjects = allTreeData.map((nodeModelClass) => nodeModelClass.getRowKV())
        await sheetClient.clearAllNodeModels()
        await sheetClient.addNodeModels(rowObjects)
      }
      setWritingEvents(true)
      return clearAndCreateRows()
    },
    onSuccess: () => {
      // グループ課題・資料作成->グループ移動->一覧に戻るで作ったグループ課題・資料がダブる現象をおこなさいために、グループ更新ごとにeventsを再読み込みする
      // https://github.com/sakawateam/printhub-front/issues/552
      queryClient.invalidateQueries({ queryKey: ["events"] })
    },
    onError: () => {
    },
    onSettled(data, error, variables, context) {
      setWritingEvents(false)
      queryClient.invalidateQueries({ queryKey: [nodeModelBaseQueryKey] })
    },
  })

  const updateNodeModel = useMutation({
    mutationFn: (nodeModel:NodeModelClass) => { // 引数はNodeModelClassにするので呼ぶ側で変換する
      setWritingEvents(true)
      return nodeModel.update()
    },
    onSuccess: () => {
      setWritingEvents(false)
      queryClient.invalidateQueries({ queryKey: [nodeModelBaseQueryKey] })
    },
    onError: () => {
      setWritingEvents(false)
      queryClient.invalidateQueries({ queryKey: [nodeModelBaseQueryKey] })
    }
  })

  const addNodeModel = useMutation({
    mutationFn: (nodeModel:NodeModel) => {
      setWritingEvents(true)
      return sheetClient.addNodeModel(nodeModel)
    },
    onSuccess: () => {
      setWritingEvents(false)
      queryClient.invalidateQueries({ queryKey: [nodeModelBaseQueryKey] })
    },
    onError: () => {
      setWritingEvents(false)
      queryClient.invalidateQueries({ queryKey: [nodeModelBaseQueryKey] })
    }
  })

  // optimistic updateにしたい
  // https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates#via-the-cache
  const deleteNodeModels = useMutation({
    mutationFn: (nodeModels:NodeModelClass[]) => {
      setWritingEvents(true)
      return Promise.all(nodeModels.map(async (nodeModel) => {
        return await nodeModel.delete()
      }))
    },
    onSuccess: () => {
      setWritingEvents(false)
      queryClient.invalidateQueries({ queryKey: [nodeModelBaseQueryKey] })
    },
    onError: () => {
      setWritingEvents(false)
      queryClient.invalidateQueries({ queryKey: [nodeModelBaseQueryKey] })
    }
  })

  const mutateSubmissionGrade = useMutation({
    mutationFn: (props:MutateSubmissionGradeProps) => {
      const { submission, grade } = props
      return gradeSubmission(submission, grade)
    },
  })

  const mutateRealtimeReloadInterval = useMutation({
    mutationFn: (reloadInterval:number) => {
      const currentConfigCopy = _.cloneDeep(configJsonBody)
      currentConfigCopy.realtimeReloadInterval = reloadInterval
      return updateConfigJson(currentConfigCopy, configJsonFileId)
    },
    onSuccess: (data) => {
      // dataはidしか返ってこないのでinvalidateする
      queryClient.invalidateQueries({ queryKey: ['configJson'] })
    },
  })

  const reloadEvents = async () => {
    setReloadingEvents(true)
    await queryClient.invalidateQueries({ queryKey: ['events'] })
    setReloadingEvents(false)
  }

  // optimistic updateにしたい
  // https://tanstack.com/query/latest/docs/framework/react/guides/optimistic-updates#via-the-cache
  const mutateEvent = useMutation({
    mutationFn: async (props:MutateEventProps) => {
      const { event, eventUpdatableProps } = props
      const newEvent = await event.update(eventUpdatableProps)

      if (newEvent.data.updatedChildEventDatas) { // グループイベントの場合
        const newChildEvents = newEvent.data.updatedChildEventDatas.map(eventData => new Event(eventData))
        return [newEvent, ...newChildEvents]
      } else { // グループイベント以外
        return [newEvent]
      }
    },
    onSuccess: async (newEvents) => {
      queryClient.setQueryData(["events"],(oldEvents:Event[]) => {
        if (oldEvents) {
          const newEventIds = newEvents.map(event => event.id)
          const oldDataWithoutUpdatedItems = oldEvents.filter(oldEvent => !newEventIds.includes(oldEvent.id))
          return [...oldDataWithoutUpdatedItems, ...newEvents]            
        } else {
          return oldEvents
        }
      })
    },
  })

  function getCoursesUnderTheGroup(event: Event, myResources: Resource[]) {
    const courseIds = getAllCourseIdsUnderAResource(String(event.resource), myResources);
    const coursesUnderTheGroup = courses.filter((course) => courseIds.includes(course.id));
    return coursesUnderTheGroup;
  }

  const getCourseFromResource = (resource:any) => {
    return courses.find((course) => course.id == resource)
  }

  const isGroupEvent = (resource:any) => {
    return !(getCourseFromResource(resource))
  }

  const createEvents = useMutation({
    mutationFn: async (props: CreateEventsProps) => {
      const { newEventData, resources} = props
      const newEvent = new Event(newEventData)
      if(isGroupEvent(newEvent.resource)){
        const childCourses = getCoursesUnderTheGroup(newEvent, resources)
        const newChildEvents = await Promise.all(childCourses.map(async (course) => {
          const newChildData = _.cloneDeep(newEventData)
          newChildData.updateResource(course.id)
          const savedEventData = await newEventData.create(course)
          const newChildEvent = new Event(savedEventData)
          return newChildEvent
        }))
        const courseWorkAndCourseIds:CourseWorkAndCourseId[] = newChildEvents.map(item => item.idAndCourseId())
        const groupData = new GroupWithSheets(sheetClient)
        await groupData.createGroup(newEvent, courseWorkAndCourseIds)
        newEvent.data = groupData
        return [newEvent, ...newChildEvents]
      } else {
        const course = courses.find((course) => newEventData.resource() == course.id);
        const savedEventData = await newEventData.create(course)
        const myNewEvent = new Event(savedEventData)
        return [myNewEvent]
      }
    },
    onSuccess: async (data) => {
      setWritingEvents(false)
      queryClient.setQueryData(["events"],(oldData:Event[]) => {
        if (oldData) {
          return [...oldData, ...data]
        } else {
          return oldData
        }
      })
    },
    onError: async (error) => {
      console.error(error)
      setWritingEvents(false)
      await queryClient.invalidateQueries({ queryKey: ['events'] })
      await queryClient.invalidateQueries({ queryKey: ['eventsWithGroupEvents'] })
    }
  })

  const deleteEvents = useMutation({
    mutationFn: async (event: Event) => {
      const progressName = `deleteEvents${event.id}`
      // snackbarの中で行う都合上、連続で削除すると上書きされてonSettledが走らない場合があるので、こちらでprogress管理する
      startProgress(progressName)
      setWritingEvents(true)
      const ids: string[] = event.childEventIds()
      if (ids) {
        const childEvents = eventsWithGroupEvents.filter((item:Event) => ids.includes(item.id))
        await Promise.all(childEvents.map(async (event:Event) => {
          return await event.delete()
        }))
      }
      await event.delete()
      return event
    },
    onSuccess: async (data) => {
      setWritingEvents(false)
      // https://tanstack.com/query/latest/docs/framework/react/guides/updates-from-mutation-responses
      queryClient.setQueryData(["events"],(oldData:Event[]) => {
        if (oldData) {
          return oldData.filter(event => event.id !== data.id && !data.childEventIds?.()?.includes(event.id))
        } else {
          return oldData
        }
      })
    },
    onError: async (error) => {
      console.error(error)
      setWritingEvents(false)
      await queryClient.invalidateQueries({ queryKey: ['events'] })
      await queryClient.invalidateQueries({ queryKey: ['eventsWithGroupEvents'] })
    },
    onSettled(data, error, variables, context) {
      const progressName = `deleteEvents${variables.id}`
      stopProgress(progressName)
    },
  })

  const createGoogleAppFile = useMutation({
    mutationFn: async (appName: string) => {
      startProgress("createGoogleAppFile")
      const data = await createGoogleApplicationFile(appName);
      return data
    },
    onSettled(data, error, variables, context) {
      stopProgress("createGoogleAppFile")
    },
    onError: async (error) => {
      console.error(error)
    }
  })

  const nodeModelIsLoading =  myNodeModelIsLoading || eventsAreLoading

  useEffect(() => {
    if (coursesAreLoading || eventsAreLoading || reloadingEvents || myNodeModelIsLoading || sheetClientIsLoading || checkingDiffBetweenCoursesAndNodeModel) {
      startProgress("useDomainData")
    } else {
      stopProgress("useDomainData")
    }
  }, [coursesAreLoading, eventsAreLoading, reloadingEvents, myNodeModelIsLoading, sheetClientIsLoading, checkingDiffBetweenCoursesAndNodeModel])

  return (
    <DomainDataContext.Provider value={{
      courses: courses,
      courseIsLoading: coursesAreLoading,
      topicIsLoading: eventsAreLoading,
      nodeModels,
      nodeModelIsLoading,
      events: events,
      eventIsLoading: eventsAreLoading,
      updateNodeModels,
      updateNodeModel,
      addNodeModel,
      deleteNodeModels,
      reloadEvents,
      sheetClient: sheetClient,
      eventsWithGroupEvents,
      mutateSubmissionGrade,
      configJson,
      mutateRealtimeReloadInterval,
      mutateEvent,
      createEvents,
      deleteEvents,
      createGoogleAppFile,
    }}>
      {children}
    </DomainDataContext.Provider>
  )
}

export function useDomainData() {
  return useContext(DomainDataContext);
}
